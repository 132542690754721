import React, { ReactNode } from "react";
import style from "./Terminal.module.css";

interface ITerminal {
  children?: ReactNode;
}

const Terminal: React.FC<ITerminal> = ({ children }) => {
  return (
    <div className={style["container"]}>
      <div className={style["terminal-header"]}>
        <button
          style={{ backgroundColor: "#FF5950" }}
          className={style["button-round"]}
        />
        <button
          style={{ backgroundColor: "#E7BD2C" }}
          className={style["button-round"]}
        />
        <button
          style={{ backgroundColor: "#56C62C" }}
          className={style["button-round"]}
        />
      </div>
      <div className={style["terminal-text-field"]}>
        <pre>{children}</pre>
      </div>
    </div>
  );
};

export default Terminal;
