import React, { useState } from "react";
import { useTypewriter } from "react-simple-typewriter";
import Terminal from "../Terminal";

const PricipalsPage: React.FC = () => {
  const [currentKey, setCurrentKey] = useState(0);

  const handleDone = (index: number) => {
    console.log("update");
    setCurrentKey(index);
  };

  const [text] = useTypewriter({
    words: ["npm start ./SCAS-Philosophy"],
    loop: 1,
    onLoopDone: () => handleDone(1),
    typeSpeed: 40,
  });

  return (
    <Terminal>
      <div className="line">
        <p style={{ color: "#9f7aea" }}>SCAS-App:~ as</p>
        {text}
      </div>
      {currentKey >= 1 && (
        <>
          <div className="line"><br /></div>
          <div className="line">
            <p style={{ color: "#9f7aea" }}>Transparency</p>
          </div>
          <div className="line">
            We commit to providing accurate and clear data,
          </div>
          <div className="line">without any external data sources</div>
          <div className="line"><br /></div>
          <div className="line">
            <p style={{ color: "#9f7aea" }}>Accuracy</p>
          </div>
          <div className="line">
            We commit to analyse data without compromises,
          </div>
          <div className="line">to ensure unbiased results</div>
          <div className="line"><br /></div>
          <div className="line">
            <p style={{ color: "#9f7aea" }}>Openness</p>
          </div>
          <div className="line">
            We commit to developing open source code when ever possible,
          </div>
          <div className="line">especially for rating algorithm’s</div>
        </>
      )}
    </Terminal>
  );
};

export default PricipalsPage;
