import "./App.css";
import AboutPage from "./components/AboutPage";
import ConsultationPage from "./components/ConsultationPage";
import MainPage from "./components/MainPage";
import { Link, Route, Routes } from "react-router";
import PricipalsPage from "./components/PrincipasPage";
import JoinBetaPage from "./components/JoinBetaPage";
import Card from "./components/CardPage";

function App() {

  const buttonContact = [
    "                                                                               ",
    "               +------------------+                                            ",
    "               |      Contact     |                                            ",
    "               +------------------+                                            ",
    "                                                                               ",
  ];
  const Consultation = [
    "                                                                               ",
    "               +21 Audits Conducted                                            ",
    "                                                                               ",
    "               +5  First Place in Competions                                   ",
    "                                                                               ",
    "               +10M Live Funds protected                                       ",
    "                                                                               ",
  ];
  const priorWork = [
    "                                                                               ",
    "  Client: Sherlock                                                             ",
    "        _____ _               _            _                                   ",
    "       / ____| |             | |          | |                                  ",
    "      | (___ | |__   ___ _ __| | ___   ___| | __                               ",
    "       \\___ \\| '_ \\ / _ \\ '__| |/ _ \\ / __| |/ /                               ",
    "       ____) | | | |  __/ |  | | (_) | (__|   <                                ",
    "      |_____/|_| |_|\\___|_|  |_|\\___/ \\___|_|\\_\\                               ",
    "                                                                               ",
    "                                                                               ",
    "                                                                               ",
    "  Client: BailSec                                                              ",
    "        ____        _ _  _____                                                      ",
    "      |  _ \\      (_) |/ ____|                                                    ",
    "      | |_) | __ _ _| | (___   ___  ___                                           ",
    "      |  _ < / _` | | |\\___ \\ / _ \\/ __|                                          ",
    "      | |_) | (_| | | |____) |  __/ (__                                           ",
    "      |____/ \\__,_|_|_|_____/ \\___|\\___|                                          ",
    "                                                                               ",
    "                                                                               ",
  ];
  return (
    <div className="App">
      <header className="App-header">
        <nav>
          <Link className="App-header-logo" to="./">
            SCAuditStudio
          </Link>
          <div className="App-header-Link-Collection">
            <Link className="App-header-Link" to="./about">
              About
            </Link>
            <Link to="./beta" className="inputControls_button--primary">
              Join Beta
            </Link>
          </div>
        </nav>
      </header>
      <div className="split-container">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <MainPage />
                <ConsultationPage />
              </>
            }
          />
          <Route
            path="/about"
            element={
              <>
                <AboutPage /> <PricipalsPage />
              </>
            }
          />
          <Route path="/consultation" element={<></>} />
          <Route
            path="/beta"
            element={
              <>
                <JoinBetaPage />
              </>
            }
          />
          <Route
            path="/card"
            element={
              <>
                <Card />
              </>
            }
          />
        </Routes>
      </div>
    </div>
  );
}

export default App;
