import React, { useEffect, useState } from 'react';
import styles from './CardPage.module.css';
import axios from 'axios';

function setColorFromSecScore(
  percentage: number,
  color1: string,
  color2: string,
  color3: string,
) {
  if (percentage < 0 || percentage > 100) {
    throw new Error("Percentage must be between 0 and 100");
  }

  const parseColor = (colorStr: string) => {
    const color = colorStr.startsWith("#") ? colorStr.substring(1) : colorStr;
    const r = parseInt(color.substring(0, 2), 16);
    const g = parseInt(color.substring(2, 4), 16);
    const b = parseInt(color.substring(4, 6), 16);
    return { r, g, b };
  };

  const color1RGB = parseColor(color1);
  const color2RGB = parseColor(color2);
  const color3RGB = parseColor(color3);

  const lerp = (start: number, end: number, amt: number) =>
    Math.round(start + (end - start) * amt);

  let r, g, b;

  if (percentage <= 50) {
    // Interpolate between color1 and color2
    const lerpPercentage = percentage / 50; // Normalized between 0 and 1
    r = lerp(color1RGB.r, color2RGB.r, lerpPercentage);
    g = lerp(color1RGB.g, color2RGB.g, lerpPercentage);
    b = lerp(color1RGB.b, color2RGB.b, lerpPercentage);
  } else {
    // Interpolate between color2 and color3
    const lerpPercentage = (percentage - 50) / 50; // Normalized between 0 and 1
    r = lerp(color2RGB.r, color3RGB.r, lerpPercentage);
    g = lerp(color2RGB.g, color3RGB.g, lerpPercentage);
    b = lerp(color2RGB.b, color3RGB.b, lerpPercentage);
  }

  const toHex = (value: number) => value.toString(16).padStart(2, "0");

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

function numToRating(num: number) {
  const ratings = ["AAA", "AA", "A", "BBB", "BB", "B", "CCC", "CC"];
  for (let i = 90; i >= 20; i -= 10) {
    if (num >= i) {
      return ratings[(90 - i) / 10];
    }
  }
  return "C";
}

type data = {
        name: string;
        auditAmount: number;
        score: number;
        category: string;
        logoURL: string;
    };

const Card: React.FC = () => {

      const [data, setData] = useState<data | null>(null);
      
      useEffect(() => {
        const postData = async () => {
          try {
            const res = await axios.post(
              "https://api.scauditstudio.com/report/preview/published/all"
            );
            setData(res.data[8]);
          } catch (error) {
            console.error("Error submitting data", error);
          }
        };
        postData();
      }, []); // Runs only once when the component mounts
    
    if(data === null){
        return <></>
    }
    return (
        <div style={{background:"#191E2A",height:"100vh",display:"flex",alignItems:"center"}}>
        <div className={styles.card}>
            <div className={styles.logoContainer}>
                <img src={data.logoURL} className={styles.logo} />
                <div className={styles.details}>
                    <h2 className={styles.title}>{data.name}</h2>
                    <div className={styles.audits}>
                    <span >Audits: </span>
                    <span className={styles.audit}>{data.auditAmount}</span>
                    </div>
                </div>
                
            </div>
            <div>
            <div className={styles.rating}>
                    <span style={{background: setColorFromSecScore(data.score, "#FF6384", "#FFCE56", "#4BC0C0")}} className={styles.grade}>{numToRating(data.score)}</span>
                    <span style={{color: setColorFromSecScore(data.score, "#FF6384", "#FFCE56", "#4BC0C0")}} className={styles.score}>{data.score.toFixed(2)}</span>
                </div>
            </div>
            <p>Certified by SCAS, scauditstudio.com</p>
        </div>
        </div>
    );
};

export default Card;