import React, { useEffect, useState } from "react";
import { useTypewriter } from "react-simple-typewriter";
import Terminal from "../Terminal";
import axios from "axios";

const ConsultationPage: React.FC = () => {
  const [currentKey, setCurrentKey] = useState(0);
  const [response, setResponse] = useState<string>();

  const handleDone = async (index: number) => {
    console.log("update");
    setCurrentKey(index);
  };

  const [text] = useTypewriter({
    words: ["curl get SCAS-API Example"],
    loop: 1,
    onLoopDone: () => handleDone(1),
    typeSpeed: 40,
  });

  useEffect(() => {
    const postData = async () => {
      try {
        const res = await axios.post(
          "https://api.scauditstudio.com/vulnerability/range",
          [0, 10],
        );
        setResponse(JSON.stringify(res.data.reports[0], null, 2));
      } catch (error) {
        console.error("Error submitting data", error);
      }
    };
    postData();
  }, []); // Runs only once when the component mounts

  return (
    <Terminal>
      <div className="line">
        <p style={{ color: "#9f7aea" }}>SCAS-App:~ as</p>
        {text}
      </div>
      {currentKey >= 1 && (
        <>
        <pre
          style={{
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
            textAlign: "left",
            fontSize: "10px",
            color: "#a0a0b0",
          }}
        >
          {response}
          <br />.......<br />
        </pre>
        <div className="line" style={{color: "#a0a0b0"}}>
          View API Docs to learn how to integrate SCAS into your product
        </div>
        </>
      )}
      
    </Terminal>
  );
};

export default ConsultationPage;
