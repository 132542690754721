import React, { useState } from "react";
import { useTypewriter } from "react-simple-typewriter";
import Terminal from "../Terminal";

const JoinBetaPage: React.FC = () => {
  const [currentKey, setCurrentKey] = useState(0);
  const [pressed, setPressed] = useState(false);

  const handleDone = (index: number) => {
    console.log("update");
    setCurrentKey(index);
  };

  const [text] = useTypewriter({
    words: ["npm start ./SCAS-Beta-Join"],
    loop: 1,
    onLoopDone: () => handleDone(1),
    typeSpeed: 40,
  });

  return (
    <Terminal>
      <div className="line">
        <p style={{ color: "#9f7aea" }}>SCAS-App:~ as</p>
        {text}
      </div>
    </Terminal>
  );
};

export default JoinBetaPage;
