export const asciiArtLogo = [
  "                                                                                                              ",
  "                                   WW  WW                                                                     ",
  "                               WWNXXW  NK WW                                                                  ",
  "                            WNXK00OKN  X0 NX N                                                                ",
  "                        WNXK0OOOOO0KN  X0 NK XNX W                                                            ",
  "                     WNK0OOOOOO00KNW   WN NK KX0 XNX W                                                        ",
  "                  WXK0OkkkkOO0XNW          W NN0 XXO XXXW                                                     ",
  "                  N0kkkkkOKXNW                WN NXO XKOXW                                                    ",
  "                  N0kkxk0N         WN...NW        WX XKOXW                                                    ",
  "                  NOxxxkKW     WWWXk.....xKNWW       WNKXW                                                    ",
  "                  NOxxxx0W  WWWNNXx.......dKNNNWW      NNW                SCAUDITSTUDIO App                   ",
  "                  NOdxxx0W  NNXXXXkl.....ddKXXXXN      NNW                VERSION 0.1.2-beta                  ",
  "                  NOdddd0W  XXXXXXKOxdoddk0XXXXXX      NNW                                                    ",
  "                  Nkdddd0W    XKKKKKKKKKKKKKKKK        NNW                Currently in beta                   ",
  "                  Nkoood0W        XKKKKKKKK          WX0XW                                                    ",
  "                  XkooooONW                      WNK KOxKW                                                    ",
  "                  XkoooooxO0XWW               WX K0x 0OxKW                                                    ",
  "                  WKkxollooodxOKNW        WN KKx 00d KKKN                                                     ",
  "                    WNKOxolllllodk0XW  NX Xk k0x 0K0 NW                                                       ",
  "                        WX0kdllllllxX  0o Kx kKO NW                                                           ",
  "                           WNKOxolldX  kl KO KWW                                                              ",
  "                              WWX0xkX  0x NW                                                                  ",
  "                                  WWW  WW                                                                     ",
  "                                                                                                              ",
];

export const oot2k = [
  "                                  ",
  "                                  ",
  "             .:=++=:.             ",
  "            :@@@@@@@@-            ",
  "           -@@@@@@@@@@-           ",
  "           =@@@@@@@@@@+                     Oot2k  ",
  "           .%@@@@@@@@%.                     Founder, Smart Contract Security Researcher.    ",
  "            .=@@@@@@=.                      Oot2k has contributed to various decentralization projects, focusing on smart contracts.",
  "         .+@@*-....:*@@+.                   With extensive experience conducting audits, he identified a lack of transparency. ",
  "       .+@@@@@@@@@@@@@@@@*.                 This realization led to the creation of SCAS, aimed at addressing these challenges.",
  "      .*@@@@@@@@@@@@@@@@@@#.      ",
  "      +@@@@@@@@@@@@@@@@@@@@+      ",
  "      @@@@@@@@@@@@@@@@@@@@@@      ",
  "     ..::--==++****+++=--::..     ",
];
export const phix = [
  "                                  ",
  "                                  ",
  "             .:=++=:.             ",
  "            :@@@@@@@@-            ",
  "           -@@@@@@@@@@-           ",
  "           =@@@@@@@@@@+                     Phillip  ",
  "           .%@@@@@@@@%.                     Co-Founder and Lead Developer with long history of .NET development.",
  "            .=@@@@@@=.                      Phillip started programming with video game development,",
  "         .+@@*-....:*@@+.                   which quickly turned from a hobby to a freelancing career. ",
  "       .+@@@@@@@@@@@@@@@@*.                 All those years of experience enable him to take SCAS to a new level.",
  "      .*@@@@@@@@@@@@@@@@@@#.      ",
  "      +@@@@@@@@@@@@@@@@@@@@+      ",
  "      @@@@@@@@@@@@@@@@@@@@@@      ",
  "     ..::--==++****+++=--::..     ",
];
