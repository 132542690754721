import React, { useState } from "react";
import { useTypewriter } from "react-simple-typewriter";
import Terminal from "../Terminal";
import { asciiArtLogo } from "../../art/logo";

const generateGradient = (index: number, total: number) => {
  const startColor = [159, 122, 234]; // #9f7aea
  const endColor = [255, 105, 180]; // pink
  const ratio = index / total;
  const r = Math.round(startColor[0] + ratio * (endColor[0] - startColor[0]));
  const g = Math.round(startColor[1] + ratio * (endColor[1] - startColor[1]));
  const b = Math.round(startColor[2] + ratio * (endColor[2] - startColor[2]));
  return `rgb(${r},${g},${b})`;
};
const buttonArt = "\n\nJoin Beta -> \n------------";
const buttonArtDeocs = "\n\nView API Docs -> \n----------------";
interface IJoinButton {
  handleDone: (number : number) => void;
}
const JoinButton: React.FC<IJoinButton> = ({handleDone}) => {
  const [text2] = useTypewriter({
    words: ["[...................................................................................]"],
    loop: 1,
    onLoopDone: () => handleDone(2),
    typeSpeed: 10,
  });
  
  return(<>{text2}</>);
}
const MainPage: React.FC = () => {
  const [currentKey, setCurrentKey] = useState(0);
  const [pressed, setPressed] = useState(false);

  const handleDone = (index: number) => {
    console.log("update " + index);
    if(index < currentKey) return;
    setCurrentKey(index);
  };

  const [text] = useTypewriter({
    words: ["npm start ./SCAS-Beta-App"],
    loop: 1,
    onLoopDone: () => handleDone(1),
    typeSpeed: 40,
  });

  return (
    <Terminal>
      <div className="line">
        <p style={{ color: "#9f7aea" }}>SCAS-App:~ as</p>
        {text}
      </div>
      {currentKey >= 1 &&
        asciiArtLogo.map((line, lineIndex) => (
          <div key={lineIndex} className="line">
            <span
              style={{
                color: generateGradient(lineIndex, asciiArtLogo.length),
              }}
            >
              {line}
            </span>
          </div>
        ))}
      {currentKey >= 1 && <div className="line">
      {
        <>Loading <JoinButton handleDone={handleDone}/></>
      }
      </div>
      }
      {currentKey >= 2 && (
        <pre
          onClick={() => setPressed(!pressed)}
          style={{ cursor: "pointer", userSelect: "none",textAlign: "left", whiteSpace: "pre-wrap",
            wordWrap: "break-word"}}
        >
          <>{buttonArt}</>
          <>{buttonArtDeocs}</>
        </pre>
      )}
    </Terminal>
  );
};

export default MainPage;
