import React, { useState } from "react";
import { useTypewriter } from "react-simple-typewriter";
import Terminal from "../Terminal";
import { oot2k, phix } from "../../art/logo";

const AboutPage: React.FC = () => {
  const [currentKey, setCurrentKey] = useState(0);

  const handleDone = (index: number) => {
    console.log("update");
    setCurrentKey(index);
  };

  const [text] = useTypewriter({
    words: ["npm start ./SCAS-Beta-App"],
    loop: 1,
    onLoopDone: () => handleDone(1),
    typeSpeed: 40,
  });

  return (
    <Terminal>
      <div className="line">
        <p style={{ color: "#9f7aea" }}>SCAS-App:~ as</p>
        {text}
      </div>
      {currentKey >= 1 &&
        oot2k.map((line, lineIndex) => (
          <div key={lineIndex} className="line">
            <span>{line}</span>
          </div>
        ))}
      {currentKey >= 1 &&
        phix.map((line, lineIndex) => (
          <div key={lineIndex} className="line">
            <span>{line}</span>
          </div>
        ))}
    </Terminal>
  );
};

export default AboutPage;
